











































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import { FinanceAccountDetails, Import, Transaction } from '../../../types';
import { ApiResponse, Pagination } from '@/components/types';
import {
  ARE_TRANSACTIONS_LOADING,
  FETCH_TRANSACTIONS,
  GET_IMPORTS,
  GET_TRANSACTIONS,
  SET_TRANSACTION_PAGE
} from '../../../store/financeAccount';
import { PaginatableMixin } from '@/views/mixin';
import CreateContraEntryDialog from '../dialogs/CreateContraEntryDialog.vue';
import CancelTransactionDialog from '../dialogs/CancelTransactionDialog.vue';
import UpdateTransactionDialog from '../dialogs/UpdateTransactionDialog.vue';
import ReverseTransactionDialog from '../dialogs/ReverseTransactionDialog.vue';
import CreateTransactionDialog from '../dialogs/CreateTransactionDialog.vue';
import ImportTransactionDialog from '@/modules/listOfAssets/components/details/dialogs/ImportTransactionDialog.vue';
import UpdateContraEntryDialog from '@/modules/listOfAssets/components/details/dialogs/UpdateContraEntryDialog.vue';
import AppTable from '@/components/AppTable.vue';
import TransactionSearchExportDialog from '@/modules/listOfAssets/components/TransactionSearchExportDialog.vue';
import CardHeader from '@/components/CardHeader.vue';
import VoucherUploadDialog from '@/modules/listOfAssets/components/details/dialogs/VoucherUploadDialog.vue';
import ViewVoucherDialog from '@/modules/listOfAssets/components/details/dialogs/ViewVoucherDialog.vue';
import RemoveVoucherDialog from '@/modules/listOfAssets/components/details/dialogs/RemoveVoucherDialog.vue';
import CsvUploadDialog from "@/modules/listOfAssets/components/details/dialogs/CsvUploadDialog.vue";

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    CsvUploadDialog,
    RemoveVoucherDialog,
    ViewVoucherDialog,
    VoucherUploadDialog,
    CardHeader,
    TransactionSearchExportDialog,
    AppTable,
    UpdateContraEntryDialog,
    ImportTransactionDialog,
    CreateTransactionDialog,
    ReverseTransactionDialog,
    UpdateTransactionDialog,
    CancelTransactionDialog,
    CreateContraEntryDialog,
    ListSearchDialog
  }
})
export default class TransactionCard extends PaginatableMixin {
  @Prop({ type: Object, required: true }) account!: FinanceAccountDetails;

  @FinanceAccount.Action(FETCH_TRANSACTIONS) fetchTransactions!: (financeAccount: string) => Promise<ApiResponse>;
  @FinanceAccount.Getter(GET_TRANSACTIONS) getTransactions!: (financeAccount: string) => { items: Transaction[], page: number };
  @FinanceAccount.Getter(ARE_TRANSACTIONS_LOADING) loadingState!: (financeAccount: string) => boolean;
  @FinanceAccount.Getter(GET_IMPORTS) imports!: () => Import[];
  @FinanceAccount.Mutation(SET_TRANSACTION_PAGE) setPageByFinanceAccount!: ({ finance_account_id, page }: { finance_account_id: string, page: number }) => void;

  loading: boolean = false;
  pagination: Pagination = {
    itemsPerPage: 20,
    page: 1,
    pageCount: 0
  };

  showMenu: boolean = false;
  x: number | null = null;
  y: number | null = null;

  transaction: null | Transaction = null;
  contraEntry: null | Transaction = null;
  cancel: null | Transaction = null;
  reverse: null | Transaction = null;
  update: null | Transaction = null;
  updateContraEntry: null | Transaction = null;
  voucher: null | Transaction = null;
  viewVoucher: null | Transaction = null;
  removeVoucher: null | Transaction = null;

  get transactionLoading() {
    return this.loadingState(this.account.id);
  }

  setContraEntry(transaction: Transaction) {
    if (transaction.contraEntry && transaction.contraEntry.id) return;

    this.contraEntry = transaction;
  }

  get list() {
    return this.getTransactions(this.account.id);
  }

  get items() {
    return (this.list.items || [])
      .map(({ created, transacted, voucherNumber, ...transaction }) => ({
        ...transaction,
        transacted,
        voucherNumber,
        transactedTrans: format(transacted, 'dd.MM.yyyy'),
        sorted: `${transacted}-${voucherNumber}`,
        showMenu: false,
        x: null,
        y: null
      })).reverse();
  }

  get proposals() {
    return [...new Set(this.items.map((item) => item.reference))].sort((first: string, second: string) => {
      return first.localeCompare(second);
    });
  }

  get nextVoucherNumber(): string {
    let nextVoucherNumber: number = 0;

    this.items.slice(0, 2).forEach((transaction) => {
      const voucherNumber = parseInt(transaction.voucherNumber, 10);

      if (voucherNumber && voucherNumber > nextVoucherNumber) {
        nextVoucherNumber = voucherNumber;
      }
    });

    return (nextVoucherNumber + 1).toString();
  }

  setPage(page: number) {
    this.setPageByFinanceAccount({ finance_account_id: this.account.id, page });
  }

  @Watch('imports', { deep: true })
  onImports() {
    return this.fetchTransactions(this.account.id);
  }

  get headers() {
    return [
      { align: 'right', text: this.$i18n.t('common.transacted'), value: 'transacted', sortable: false, width: '130px' },
      { align: 'left', text: this.$i18n.t('common.voucherNumber'), value: 'voucherNumber', sortable: false, width: '100px' },
      { align: 'left', text: this.$i18n.t('common.reference'), value: 'reference', sortable: false },
      { align: 'right', text: this.$i18n.t('transaction.deposit'), value: 'deposit', sortable: false, width: '120px' },
      { align: 'right', text: this.$i18n.t('transaction.withdraw'), value: 'withdraw', sortable: false, width: '120px' },
      { align: 'right', text: this.$i18n.t('transaction.balance'), value: 'balance', sortable: false, width: '120px' },
      { align: 'right', text: '', value: null, sortable: false, width: '120px' }
    ];
  }

  itemClasses(transaction: Transaction) {
    if (transaction.type === 'withdraw') {
      return 'red--text lighten-4';
    }

    if (transaction.type === 'deposit') {
      return 'green--text lighten-4';
    }

    return '';
  }

  show(transaction: Transaction, event: MouseEvent) {
    event.preventDefault();
    this.showMenu = false;
    this.transaction = null;
    this.x = event.clientX;
    this.y = event.clientY;
    this.$nextTick(() => {
      this.transaction = transaction;
      this.showMenu = true;
    });
  }

  async fetch() {
    this.loading = true;

    await this.fetchTransactions(this.account.id);

    this.loading = false;
  }

  async created() {
    return this.fetch()
  }
}
